import { Controller } from  "stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'options']

  optionChecked(event) {
    this.checkboxTarget.checked ? this.showOptions() : this.hideOptions()
  }

  showOptions() {
    this.optionsTarget.classList.remove('options')
    this.optionsTarget.classList.add('options--show')
  }

  hideOptions() {
    this.optionsTarget.classList.remove('options--show')
    this.optionsTarget.classList.add('options')
  }
}
