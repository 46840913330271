import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['type', 'name']

  filtered() {
    let type = this.typeTarget.options[this.typeTarget.options.selectedIndex].value
    let name = this.nameTarget.options[this.nameTarget.options.selectedIndex].value

    this.setUrlParameter(type, name)
  }

  setUrlParameter(type, name) {
    let url    = window.location.origin
    let path   = window.location.pathname
    let params = ""
    url        = url + path

    if(type !== null || type !== undefined) {
      if(url.indexOf("?") < 0){
        url += "?" + "type=" + type
      } else {
        url += "&" + "type=" + type
      }
    }

    if(name !== null || name !== undefined) {
      if(url.indexOf("?") < 0){
        url += "?" + "name=" + name
      } else {
        url += "&" + "name=" + name
      }
    }

    window.location.replace(url)
  }
}
