import { Controller } from 'stimulus';
import { render } from 'mustache';
import renderError from 'helpers/errors_handler';

export default class extends Controller {

  static targets = ['item', 'show', 'list', 'form'];

  initialize() { }
  connect() {
    this.resetList()
  }

  onLoading(event) {
    this.showTarget.innerHTML = render(
      this.landingMessageTemplate(), { message: 'Loading..' }
    )
  }

  onNew(event) {
    let [data, status, xhr] = event.detail

    this.resetList()
    this.showTarget.innerHTML = xhr.response

    $(this.showTarget).find('#service_name').focus()
    $(this.formTarget).find('.card').first().addClass('border border-primary')
  }

  onShow(event) {
    let [data, status, xhr] = event.detail

    this.resetList()
    this.activateItem(event.currentTarget)
    this.showTarget.innerHTML = xhr.response
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail

    this.resetList()
    this.showTarget.innerHTML = xhr.response

    const $itemTemplate = $(this.showTarget).find('#item_template')
    const itemId = $itemTemplate.data('id')
    const $item = this.activateItem($itemTemplate.html())

    if ($itemTemplate.length) {
      if (status === 'Created') {
        $(this.listTarget).append($item)
      } else if (status === 'OK') { // Updated
        let $existingItem = $(this.listTarget).find(`#service_item_${itemId}`)
        $existingItem.replaceWith($item)
      } else {
        console.log(`Status is ${status}, expecting OK or Created.`)
      }
    }
  }

  onError(event) {
    const $form = $(this.formTarget)
    let [data, status, xhr] = event.detail
    let errors = JSON.parse(xhr.response)

    renderError($form, 'service', errors)
  }

  activateItem(item) {
    return $(item).addClass('disabled')
  }

  deactivateItem(item) {
    return $(item).removeClass('disabled')
  }

  resetList() {
    this.deactivateItem(this.itemTargets)
    this.showTarget.innerHTML = render(
      this.landingMessageTemplate(), { message: 'Nothing to show, click a service on the list.' }
    )
  }

  landingMessageTemplate() {
    return `
      <div class='h-100 d-flex justify-content-center'>
        <i class='align-self-center text-muted'>{{message}}</i>
      </div>
    `
  }
}