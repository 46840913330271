import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["files", "file"]

  addFile(event) {
    const originalInput = event.target
    const originalParent = originalInput.parentNode
    const emptyInput = 0

    this.fileTargets.forEach((input) => {
      if (input.value == "") {
        emptyInput += 1
      }
    })

    const newId = Date.now() + Math.floor(Math.random())
    const newParent = originalParent.cloneNode(true)

    newParent.querySelectorAll('input').forEach((input) => {
      input.name = input.name.replace(/\d+/g, newId)

      // Empty the file input
      if (input.type === "file") {
        input.value = ""
      }
    })

    if (emptyInput < 2){ // Check if there's already an empty file input
      this.filesTarget.append(newParent)
    }
  }
}