import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabPanel', 'tabContent']

  initialize() {
    this.showTab()
  }

  changeTab(event) {
    this.index = this.tabTargets.indexOf(event.target.parentNode)
    this.showTab(this.index)
  }

  showTab() {
    this.tabPanelTargets.forEach((element, index) => {
      this.setClassOfElement(element, index)
    })

    this.tabContentTargets.forEach((element, index) => {
      this.setClassOfElement(element, index)
    })
  }

  get index() {
    return parseInt(this.data.get('index'))
  }

  set index(value) {
    this.data.set('index', value)
    this.showTab()
  }

  setClassOfElement(element, index) {
    if(index == this.index){
      element.classList.add('active')
    } else {
      element.classList.remove('active')
    }
  }
}
