import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['modal'];

  onShow(event) {
    let [data, status, xhr] = event.detail    
    const $modal = $(xhr.response)

    $modal.modal('show')
  };
};
