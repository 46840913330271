import { Controller } from "stimulus";
import renderError from 'helpers/errors_handler';

export default class extends Controller {

  static targets = [
    'item',
    'list',
    'form',
    'itemContent',
    'landingMessage',
    'closeFormBtn',
    'listOptions',
    'valueTypeSelect',
    'typeNone',
    'typeServiceTime',
    'typeServiceDate',
    'typeSign',
    'typeOtherSign',
    'fieldTypeRadio',
    'missingRequirementsAlert'
  ];

  connect() {
    // this.updateMissingFieldAlert()
  }

  onFormSubmitted() {
    const form = event.currentTarget
    const [xhr, status] = event.detail

    switch (status) {
      case 'Created':
        this.onItemCreated(form, xhr)
        break
      case 'OK': // Updated
        this.onItemUpdated(form, xhr)
        break
      case 'Unprocessable Entity':
        this.onFormError(form, xhr)
        break
      default:
        console.log('Submitted but expecting :created, :ok or :unprocessable_entity status')
    }
  }

  onItemCreated(form, xhr){
    const $form = $(form)

    $(this.listTarget).append(xhr.response)

    if (this.hasLandingMessageTarget) {
      $(this.landingMessageTarget).addClass('d-none')
    }

    this.resetList()
  }

  onItemUpdated(form, xhr){
    const $item = $(form).closest(`[data-target='service-fields.item']`)

    $item.replaceWith(xhr.response)
    this.resetList()
  }

  deleteItem() {
    const [xhr, status] = event.detail
    const $item = $(event.currentTarget).closest(`[data-target='service-fields.item']`)

    switch (status) {
      case 'No Content':
      case 'OK': // Updated
        this.onItemDeleted($item)
        break
      case 'Unprocessable Entity':
        console.log('Can\'t delete')
        break
      default:
        console.log('Submitted but expecting :ok or :unprocessable_entity status')
    }
  }

  onItemDeleted($item) {
    $item.remove()

    if ($(this.itemTargets).length < 2) {
      $(this.landingMessageTarget).removeClass('d-none')
    }

    this.resetList()
  }

  onFormError(form, xhr) {
    const $form = $(form)
    const errors = JSON.parse(xhr.response)

    this.resetForm($form)

    renderError($form, 'service_field', errors)

    // custom
    if (errors['field_type']) {
      $form.find('#service_field_field_type_null').closest('.form-group').append(`<div class='text-danger'>${errors['field_type'][0]}</div>`)
    }
  }

  onValueTypeChanges() {
    const valueType = event.currentTarget.value
    $(this.valueTypeRadioTargets).prop('checked', false)
    this.setValueType(valueType)
  }

  showForm() {
    this.resetList()

    const [data, status, xhr] = event.detail
    const $item = $(event.currentTarget).closest(`[data-target='service-fields.item']`)
    const $itemContent = $item.find(`[data-target='service-fields.itemContent']`)

    $item.append(xhr.response).addClass('bg-light')
    $itemContent.addClass('d-none')
    $item.find('#service_field_label').focus()

    const valueType = this.valueTypeSelectTarget.value

    this.setValueType(valueType)
  }

  dismissForm() {
    event.preventDefault()
    // const $item = $(event.currentTarget).closest(`[data-target='service-fields.item']`)
    // const $form = $item.find(`[data-target='service-fields.form']`)
    // const $itemContent = $item.find(`[data-target='service-fields.itemContent']`)

    // $itemContent.removeClass('d-none')
    // $form.remove()

    // Yea, that's right.
    this.resetList()
  }

  setValueType(valueType) {
    this.resetValueType()

    switch (valueType) {
      case 'text':
        $(this.typeSignTarget).prop('disabled', false)
        $(this.typeOtherSignTarget).prop('disabled', false)
        break
      case 'list':
        $(this.typeSignTarget).prop('disabled', false)
        $(this.typeOtherSignTarget).prop('disabled', false)
        $(this.listOptionsTarget).prop('disabled', false)
        break
      case 'time':
        $(this.typeServiceTimeTarget).prop('disabled', false)
        break
      case 'date':
        $(this.typeServiceDateTarget).prop('disabled', false)
        break
      default:
        $(this.typeNoneTarget).prop('disabled', true).prop('checked', false)
        break
    }
  }

  resetList() {
    $(this.formTargets).remove()
    $(this.itemTargets).removeClass('bg-light')
    $(this.itemContentTargets).removeClass('d-none')

    // this.updateMissingFieldAlert()
  }

  resetForm() {
    $(this.formTarget).find('.invalid-feedback').remove()
    $(this.formTarget).find('input').removeClass('is-invalid')
  }

  resetValueType() {
    $(this.typeNoneTarget).prop('disabled', false)
    $(this.typeServiceTimeTarget).prop('disabled', true)
    $(this.typeServiceDateTarget).prop('disabled', true)
    $(this.typeSignTarget).prop('disabled', true)
    $(this.typeOtherSignTarget).prop('disabled', true)
    $(this.listOptionsTarget).prop('disabled', true)
  }

  updateMissingFieldAlert() {
    let message = ''
    const fields = []
    const $target = $(this.missingRequirementsAlertTarget)

    this.serviceDateTargets.length === 0 && fields.push('Service Date')
    this.serviceTimeTargets.length === 0 && fields.push('Service Time')

    message = `Missing: ${fields.join(' and ')}`

    if (fields.length > 0) {
      $target.removeClass('d-none').html(message)
    } else {
      $target.addClass('d-none')
    }
  }
}