import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['qty', 'price'];

  connect() {
    this.updateTotal()
  }

  updateCart(e) {
    let qty = parseFloat(e.currentTarget.value)
    const id = e.currentTarget.id.split('booking_details_')[1]
    const itemPrice = parseFloat(e.currentTarget.dataset.itemPrice)
    const maxQty = parseFloat(e.currentTarget.dataset.maxQty)
    const minPax = parseFloat(e.currentTarget.dataset.minPax)
    const price = this.calculatePrice(itemPrice, qty, maxQty, minPax)

    qty = qty < minPax ? minPax : qty

    // Perhaps jQuery selector is faster and more elegant
    this.qtyTargets.every((el) => {
      if (el.dataset.id == id) {
        el.innerHTML = qty
        el.dataset.qty = qty
        return false
      }
      return true
    })

    // Perhaps jQuery selector is faster and more elegant
    this.priceTargets.every((el) => {
      if (el.dataset.id == id) {
        el.innerHTML = price
        el.dataset.price = price
        return false
      }
      return true
    })

    this.updateTotal()
  }

  updateTotal() {
    let total = []

    this.priceTargets.forEach((el) => {
      total.push(parseFloat(el.dataset.price))
    })

    document.getElementById('total').innerHTML = this.toCurrency(this.sumTotal(total))
  }

  sumTotal(total) {
    return total.reduce((sum, x) => sum + x)
  }

  calculateQty(qty, maxQty, minPax) {
    let qtyCalc = 1
    let total_qty = qty

    if (qty < minPax) {
      total_qty = minPax
    }

    if (total_qty > maxQty) {
      qtyCalc = Math.floor(total_qty / maxQty)
      if ((qty % maxQty) > 0) {
        qtyCalc++
      }
    }

    return qtyCalc;
  }

  calculatePrice(itemPrice, qty, maxQty, minPax) {
    let price = 0.0

    // if (qty > 0) {
    qty = this.calculateQty(qty, maxQty, minPax)
    price = qty * itemPrice
    // }

    return price
  }

  toCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}
