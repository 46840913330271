import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['price', 'passports', 'amount']

  connect() {
    this.priceTarget.innerHTML  = this.formatNumber(parseFloat(this.priceTarget.innerHTML.slice(3)).toFixed(2))
    this.amountTarget.innerHTML = this.formatNumber(parseFloat(this.amountTarget.innerHTML.slice(3)).toFixed(2))
  }

  count() {
    let price     = this.priceTarget.innerHTML.slice(3).replace(",","")
    let passports = this.passportsTarget.value

    this.amountTarget.innerHTML = this.formatNumber(parseFloat(price * passports).toFixed(2))
  }

  formatNumber(num) {
    return "Rp " + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}
