// This function show error message in each fields using bootstrap 4.3 error classes
//
// `form` is the parent or wrapper to encapsulate the field selection
// `modelName` is the is the object name of rails fields
// `errors` is the JSON parsed error field and messages from rails' model.errors
//
export default function(form, modelName, errors) {
  $.each(errors, function(field, messages) {
    let $field = form.find(`#${modelName}_${field}`)

    $field.addClass('is-invalid')

    $.each(messages, function(){
      $field.parent().append(`<div class='invalid-feedback'>${this}</div>`)
    })
  })
}